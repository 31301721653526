import arrayMutators from 'final-form-arrays';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { useLocation } from 'react-router-dom';
import { RequestBuyinFunction, RequestNewBuyin } from '../../api/buyin-requests';
import { RequestGetManufacturers } from '../../api/manufacturer-requests';
import { RequestGetProductCategorys } from '../../api/product-category-requests';
import { RequestGetProductManufacturers } from '../../api/product-manufacturer-requests';
import { RequestGetProduct } from '../../api/product-requests';
import { RequestGetStocks } from '../../api/stock-requests';
import { RequestGetStore } from '../../api/store-requests';
import { promiseOptionsManufacturer, promiseOptionsProduct, promiseOptionsProductCategory, promiseOptionsProductManufacturer, promiseOptionsStore } from '../../components/promises';
import DataTable from '../../components/system/DataTable';
import DebugButton from '../../components/system/DebugButton';
import InputTextTemplate from '../../components/system/FormStuff/InputTextTemplate';
import { AsyncSelectCreateAdapter, IReactSelectValue, XAsyncSelectAdapter } from '../../components/system/FormStuff/ReactSelect';
import { composeValidators, minValue, required } from '../../components/system/FormStuff/validators';
import ImageDisplay from '../../components/system/ImageDisplay';
import Loading from '../../components/system/loading';
import UploadImages from '../../components/system/UploadImages/UploadImages';
import { ENotificationType, useNotifications } from '../../context/notifications';
import { IImage } from '../../interface/image';
import { IManufacturer } from '../../interface/manufacturer';
import { IProduct } from '../../interface/product';
import { IProductCategory, IProductCategoryDetail } from '../../interface/product-category';
import { IProductManufacturer } from '../../interface/product-manufacturer';
import { IStock } from '../../interface/stock';
import { IStore } from '../../interface/store';






interface IBuyinForm {

    productCategoryDetails?: IProductCategoryDetail[]
    data: any;
    isNewA: boolean;
    partNumberUnknown: boolean;
    lastPurchasePrice: number;
    storeId: IReactSelectValue | undefined;
    productId: IReactSelectValue | undefined;
    productCategoryId: IReactSelectValue | undefined,
    productCategory?: IProductCategory,
    productDescription: string,
    partNumber: string,
    manufacturerIds: any[] | undefined;
    manufacturer?: IManufacturer;
    productManufacturerId: IReactSelectValue | undefined;
    productManufacturer?: IProductManufacturer;
    productDetails: any;
    imageId: string;
    image?: IImage,
    createdTs?: Date,
    imagesObj?: IImage[];
    imagesIds: string[];
    newSalePrice: number,
    qty?: number,
    _totalWeight?: number;
    _weights: number[];
    weight?: number,
    price?: number;
    stock?: number;
    _storeObject?: IStore;
    _productObject?: IProduct;
    _otherStock?: IStock[];
}


const setFocusHack = (fieldId: string) => {
    if (document !== null && document.getElementById !== null) {
        const d = document.getElementById(fieldId)
        if (d !== null) {
            d.focus();
        }
    }
}

const AdminBuyIn = () => {

    const location = useLocation();

    const [loading, setLoading] = useState<boolean>(true);
    const { addNotification } = useNotifications()!;
    const [productManufacturer, setProductManufacturer] = useState<IProductManufacturer[]>();
    const [productCategories, setProductCategories] = useState<IProductCategory[]>();
    const [manufacturers, setManufacturers] = useState<IManufacturer[]>();

    const [thing, setThing] = useState<IBuyinForm>();


    useEffect(() => {
        const load = async () => {
            try {

                if (thing === undefined && loading == true) {



                    setProductManufacturer(await RequestGetProductManufacturers(addNotification, {}));
                    setManufacturers(await RequestGetManufacturers(addNotification, {}));
                    setProductCategories(await RequestGetProductCategorys(addNotification, {}));



                    let query: any = queryString.parse(location.search)

                    const catId = (query.productCategoryId ? query.productCategoryId : '')



                    setThing({ partNumberUnknown: false, isNewA: true, lastPurchasePrice: 0, productDetails: {}, _weights: [], imageId: '', storeId: undefined, manufacturerIds: [{}], productManufacturerId: undefined, productId: undefined, data: {}, productDescription: '', productCategoryId: catId, partNumber: '', price: 0, stock: 0, imagesIds: [], imagesObj: [], newSalePrice: 0, })





                    setLoading(false)
                }

            } catch (ex: any) {
                console.error(ex)
            }

        }

        load()
    }, []);

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);

            await RequestNewBuyin(addNotification, data);

            setThing({ partNumberUnknown: false, isNewA: true, lastPurchasePrice: 0, productDetails: {}, _weights: [], qty: 0, imageId: '', storeId: data.storeId, productManufacturerId: data.productManufacturerId, manufacturerIds: data.manufacturerIds, productId: undefined, data: {}, productDescription: '', productCategoryId: data.productCategoryId, partNumber: '', price: 0, stock: 0, imagesIds: [], imagesObj: [], newSalePrice: 0, })
            addNotification && addNotification('Done', 'You have added stock', ENotificationType.Success);
            setLoading(false)
        } catch (tryExc: any) {
            console.log(tryExc)

            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }

    // const focusOnError = createDecorator<IBuyinForm>()
    return (<>
        {loading === true && <Loading />}


        {loading === false && <div className="row">
            <div className="col-12 bold-cursor">

                <Form <IBuyinForm>
                    onSubmit={onSubmit}
                    initialValues={thing}
                    // decorators={[focusOnError]}
                    mutators={{
                        // expect (field, value) args from the mutator
                        ...arrayMutators,
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        }
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {


                        return <form onSubmit={handleSubmit} className="hiContrastForm" id="buyInForm">

                            <DebugButton data={JSON.parse(JSON.stringify(values))} alwaysDisplay={true} />
                            <div className="row">
                                <div className="col-12">



                                    <Field
                                        name="storeId"
                                        title="Physical Location"
                                        description="Where is this item in the warehouse"
                                        component={XAsyncSelectAdapter}
                                        promiseOptions={promiseOptionsStore}
                                        autoFocus
                                        inputId={"storeID"}



                                    />
                                    <OnChange name={`storeId`}>
                                        {(value, previous) => {
                                            const asyncHack = async () => {

                                                if (value === null) {
                                                    form.change('_storeObject', undefined);
                                                } else {
                                                    if (value.value && !value.__isNew__) {
                                                        const storeZ = await RequestGetStore(addNotification, value.value)
                                                        form.change('_storeObject', storeZ)
                                                    }
                                                }


                                            }
                                            console.log("storedId", value)
                                            asyncHack();
                                            setFocusHack('productId')
                                        }}
                                    </OnChange>

                                    {values._storeObject && <div>
                                        {values._storeObject._stock &&
                                            <DataTable data={values._storeObject._stock} colHeadings={[
                                                { field: 'productId', name: 'product', sortable: true },
                                                { field: 'qty', name: 'qty', sortable: true }
                                            ]} />}

                                    </div>}
                                    <Field
                                        name="productId"
                                        title="Part Number"
                                        description="Unique Part Number, for custom parts prefix with SBM"
                                        component={AsyncSelectCreateAdapter}
                                        promiseOptions={promiseOptionsProduct}
                                        validate={composeValidators(required)}
                                        onChange={() => { setFocusHack('qty') }}
                                        inputId={"productId"}
                                    />

                                    <Field
                                        name={`qty`}
                                        parse={(value, name) => {
                                            //  console.log("parse", value, name)
                                            return parseInt(value);
                                        }}
                                        format={(value, name) => {
                                            //  console.log("format", value, name)
                                            return parseInt(value);
                                        }} validate={composeValidators(required)}
                                        id={'qty'}
                                        className="hiContrastFormField"
                                        iType="number"
                                        text="Quantity"
                                        description="Number of this item in this place"
                                        component={InputTextTemplate}
                                    />
                                    <div className='row'>
                                        <div className="col-sm-12 col-md-2">
                                            <label>Part Number Needs Work?</label>
                                            <p className="descriptionText">Select if unsure about part number</p>
                                        </div>
                                        <div className="col-sm-12 col-md-8">


                                            <Field component="input" type="checkbox" name={`partNumberUnknown`} />
                                        </div>
                                        <div className="col-sm-12 col-md-2">

                                        </div>
                                    </div>
                                    <button type='button' className='btn btn-primary' onClick={() => {


                                        const gooo = async () => {
                                            const ddd = await RequestBuyinFunction(addNotification, 'newSBM')
                                            console.log("sssss", ddd.seq)

                                            form.change('partNumberUnknown', true)
                                            form.change('productId', { value: `SBM-${ddd.seq}`, __isNew__: true, label: `SBM-${ddd.seq}` })
                                            setFocusHack('productCategoryId')
                                        }
                                        gooo();
                                    }}>SBM Part Number</button>
                                    <OnChange name={`productId`}>
                                        {(value, previous) => {
                                            console.log("productId", value)
                                            const asyncHack = async () => {


                                                if (value === null) {
                                                    form.change('_productObject', undefined)
                                                    form.change('weight', undefined)
                                                    form.change('_otherStock', undefined)
                                                } else {
                                                    if (value.value && !value.__isNew__) {
                                                        const productZ = await RequestGetProduct(addNotification, value.value)
                                                        form.change('_productObject', productZ)
                                                        form.change('weight', productZ.weight)
                                                        const stocks = await RequestGetStocks(addNotification, { productId: value.value })
                                                        form.change('_otherStock', stocks)
                                                        setFocusHack('qty')
                                                    } else {
                                                        setFocusHack('qty')
                                                    }
                                                }

                                            }
                                            console.log("storedId", value)
                                            asyncHack();

                                        }}
                                    </OnChange>

                                    {values._productObject && <div>
                                        <h2>{values._productObject.partNumber}</h2>
                                        {values._productObject.partNumber}
                                        <div className='d-flex justify-content-between'>
                                            {/* <div>Bike Manufacturer: {values._productObject.manufacturer?.name}</div> */}
                                            <div>Part Manufacturer: {values._productObject.productManufacturer?.name}</div>
                                            <div>Category: {values._productObject.productCategory?.name}</div>
                                            <div>{values._productObject.description}</div>

                                        </div>

                                        <DebugButton data={JSON.parse(JSON.stringify(values._productObject))} alwaysDisplay={true} />
                                    </div>}


                                    {values._otherStock && <DataTable data={values._otherStock} colHeadings={[
                                        { field: 'productId', name: 'product', sortable: true },
                                        { field: 'qty', name: 'qty', sortable: true }
                                    ]} />}
                                    {values.productId && values.productId.__isNew__ === true && <div>
                                        <h2>Your adding a new Product</h2>

                                        <Field name="productCategoryId"
                                            title="Category" component={AsyncSelectCreateAdapter}
                                            promiseOptions={promiseOptionsProductCategory}
                                            defaultOptions={(productCategories !== undefined ? productCategories.map(d => {
                                                return {
                                                    value: d.id,
                                                    label: d.name
                                                }
                                            }) : [])}
                                            inputId={"productCategoryId"}
                                            description="What is this item"
                                        />
                                        <OnChange name={`productCategoryId`}>
                                            {(value, previous) => {
                                                //   console.log("productId", value)
                                                setFocusHack('productManufacturerId')
                                            }}
                                        </OnChange>



                                        {productManufacturer && productManufacturer.length}
                                        <Field name="productManufacturerId"
                                            title="Part Manufacturer"
                                            component={AsyncSelectCreateAdapter}
                                            promiseOptions={promiseOptionsProductManufacturer}
                                            defaultOptions={(productManufacturer !== undefined ? productManufacturer.map(d => {
                                                return {
                                                    value: d.id,
                                                    label: d.name
                                                }
                                            }) : [])}
                                            description="Whom made this"
                                            inputId={"productManufacturerId"}
                                        />
                                        <OnChange name={`productManufacturerId`}>
                                            {(value, previous) => {
                                                //   console.log("productId", value)
                                                setFocusHack('manufacturerId')
                                            }}
                                        </OnChange>
                                        <FieldArray name="manufacturerIds">
                                            {({ fields }) => (
                                                <div className="">
                                                    {fields.map((name, index) => {
                                                        return <div key={index} className="">
                                                            <button className='btn btn-danger' type='button' onClick={() => {
                                                                fields.remove(index)
                                                            }}>Delete ({index + 1})</button>
                                                            <Field name={name}
                                                                title={`Bike Manufacturer (${index + 1})`}
                                                                component={AsyncSelectCreateAdapter}
                                                                promiseOptions={promiseOptionsManufacturer}
                                                                defaultOptions={(manufacturers !== undefined ? manufacturers.map(d => {
                                                                    return {
                                                                        value: d.id,
                                                                        label: d.name
                                                                    }
                                                                }) : [])}
                                                                description="What Bike Manufacture is this for"
                                                                inputId={"manufacturerId"}
                                                            />
                                                            {index === 0 && <OnChange name={name}>
                                                                {(value, previous) => {
                                                                    //   console.log("productId", value)
                                                                    setFocusHack('productDescription')
                                                                }}
                                                            </OnChange>}
                                                        </div>
                                                    })}
                                                    <button className='btn btn-success' type='button' onClick={() => {
                                                        fields.push({})
                                                    }}>Add Bike Manufacture</button>
                                                </div>)}
                                        </FieldArray>

                                        <Field name="productDescription">
                                            {({ input, meta }) => (<div className='row'>
                                                <div className="col-sm-12 col-md-2">
                                                    <label>Description</label>
                                                    <p className="descriptionText">Detailed Description</p>
                                                </div>
                                                <div className="col-sm-12 col-md-8">

                                                    <textarea id="productDescription" type="text" className="hiContrastFormField" {...input} placeholder="...." />
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            </div>
                                            )}
                                        </Field>

                                        <Field
                                            name="productManufacturerCode"
                                            text="Manufacturer Part Code"
                                            className="hiContrastFormField"
                                            description="Manufacturer's Part Number"
                                            component={InputTextTemplate}
                                            inputId={"productManufacturerCode"}
                                        />



                                        <div className="col-12">

                                            <h3>Product Images</h3>
                                            <FieldArray name="imagesObj">
                                                {({ fields }) => (
                                                    <div className="row">
                                                        {fields.map((name, index) => {
                                                            let src: string = '';


                                                            if (values.imagesObj && values.imagesObj[index]) {
                                                                const id = values.imagesObj[index].id

                                                                return <div key={name} className="col-4">
                                                                    <ImageDisplay image={values.imagesObj[index]} />
                                                                    <div>
                                                                        {/* <label>Name - doesnt work yet</label>
                                                                        <Field name={`${name}.name`} component="input" /> */}
                                                                    </div>
                                                                    <div className="btn-group">
                                                                        {/* {values.imagesObj[index] && values.imagesObj[index].id !== values.imageId && <button className="btn btn-success" type="button" onClick={() => {


                                                                            form.change('imageId', id)
                                                                            values.imagesObj && form.change('image', values.imagesObj[index])

                                                                        }}>Set Primary</button>} */}
                                                                        <button className="btn btn-danger" type="button" onClick={() => {
                                                                            if (values.imagesObj) {
                                                                                const imageIds: string[] = values.imagesIds
                                                                                const index: number = values.imagesObj && imageIds.findIndex(dd => dd === id);
                                                                                imageIds.splice(index, 1);
                                                                                form.change('imagesIds', imageIds)
                                                                                fields.remove(index)
                                                                            }


                                                                        }}>Remove</button>
                                                                    </div>
                                                                </div>

                                                            }
                                                        })}

                                                        <div className="col-12 w-100">
                                                            <UploadImages text={"Take Photo For this item"} aspect="none" name="product" attachId={"newProduct"} attachTo={"product"} save={(dd: any) => {

                                                                const imageIds: string[] = values.imagesIds
                                                                imageIds.push(dd.id);


                                                                form.change('imagesIds', imageIds)
                                                                fields.push(dd)

                                                                if (imageIds.length === 1) {
                                                                    form.change('imageId', dd.id)
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>
                                                )}
                                            </FieldArray>
                                            <OnChange name={`imagesObj`}>
                                                {(value, previous) => {

                                                    console.log("imageId", value)
                                                    form.submit()
                                                }}
                                            </OnChange>

                                        </div>
                                    </div>}


                                    <Field
                                        name="weight"
                                        disabled={(values.productId && values.productId.__isNew__ === false)}
                                        parse={(value, name) => {
                                            //  console.log("parse", value, name)
                                            return parseFloat(value);
                                        }}
                                        format={(value, name) => {
                                            //  console.log("format", value, name)
                                            return parseFloat(value);
                                        }}
                                        className="hiContrastFormField"
                                        iType="number"
                                        text="Weight"
                                        id="weight"
                                        description="Wight in (g)"
                                        component={InputTextTemplate}
                                    />

                                    <FieldArray name="_weights">
                                        {({ fields }) => (

                                            <div className="">
                                                <button type='button' className='btn btn-primary' onClick={() => {
                                                    fields.push(0)
                                                }}>Add Weight Total</button>
                                                {fields.map((d, dIndex) => <Field
                                                    key={dIndex}
                                                    name={d}
                                                    parse={(value, name) => {
                                                        //  console.log("parse", value, name)
                                                        return parseInt(value);
                                                    }}
                                                    format={(value, name) => {
                                                        //  console.log("format", value, name)
                                                        return parseInt(value.toString());
                                                    }} v
                                                    alidate={composeValidators(required, minValue(1))}
                                                    id={'qty'}
                                                    className="hiContrastFormField"
                                                    iType="number"
                                                    text={`Weight ${dIndex + 1}`}
                                                    description="Number of this item in this place"
                                                    component={InputTextTemplate}
                                                />)}

                                            </div>
                                        )}

                                    </FieldArray>
                                    <OnChange name={`_weights`}>
                                        {(value, previous) => {
                                            if (value != previous) {
                                                console.log(value)

                                                const valued = value.reduce((sum: number, current: number) => sum + current, 0);

                                                form.change('_totalWeight', valued)


                                            }
                                        }}
                                    </OnChange>
                                    {values._totalWeight && <div className='row'>
                                        <div className="col-sm-12 col-md-2">
                                            Total Weight
                                        </div>
                                        <div className="col-sm-12 col-md-8">

                                            {values._totalWeight}
                                            <br />
                                            <button type='button' className='btn btn-success' onClick={() => {
                                                const total = (values._totalWeight ? values._totalWeight : 0);
                                                const weight = (values.weight ? values.weight : 0);
                                                form.change('qty', Math.ceil(total / weight))

                                            }}>Magic Button</button>
                                        </div>
                                        <div className="col-sm-12 col-md-2">

                                        </div>

                                    </div>}

                                    <Field
                                        name={`newSalePrice`}
                                        parse={(value, name) => {
                                            //  console.log("parse", value, name)
                                            return parseFloat(value);
                                        }}
                                        format={(value, name) => {
                                            //  console.log("format", value, name)

                                            return parseFloat(value);
                                        }}
                                        validate={composeValidators(required)}
                                        component={InputTextTemplate}
                                        iType="number"
                                        text="Sale Price GBP"
                                        description="Sale Price per item"
                                    />
                                    <Field
                                        name={`purchasePrice`}
                                        parse={(value, name) => {
                                            //  console.log("parse", value, name)
                                            return parseFloat(value);
                                        }}
                                        format={(value, name) => {
                                            //  console.log("format", value, name)

                                            return parseFloat(value);
                                        }}
                                        id={'purchasePrice'}
                                        className="hiContrastFormField"
                                        iType="number"
                                        text="Purchase Price"
                                        description="Cost Per item, (blank for current stock)"
                                        component={InputTextTemplate}
                                    />

                                    <div className='row'>
                                        <div className="col-sm-12 col-md-2">
                                            <label>Is New</label>
                                            <p className="descriptionText">De-Select if 2nd use stock</p>
                                        </div>
                                        <div className="col-sm-12 col-md-8">


                                            <Field component="input" type="checkbox" name={`isNewA`} />
                                        </div>
                                        <div className="col-sm-12 col-md-2">

                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">

                                    <DebugButton data={JSON.parse(JSON.stringify(values))} alwaysDisplay={true} />
                                    <button id="submit" className="btn btn-primary" type="submit">Submit</button>
                                </div>
                            </div>















                        </form>

                    }} />

            </div>

        </div>
        }
    </>
    );
}

export default AdminBuyIn;